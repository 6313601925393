<template>
	<div class="a-w-100 a-h-100 construction">
		<!-- <span class="a-fs-16 a-fw-700">填写施工信息</span> -->
		<el-form :model="buildInfo" ref="buildInfo" label-width="112px" class="buildInfo a-mt-16" label-position="left">
			<el-form-item label="电表编号">
				<el-input v-model="buildInfo.electricNumber" maxlength="50" placeholder="请填写"></el-input>
			</el-form-item>
			<el-form-item label="电表读数">
				<el-input v-model="buildInfo.electricInitNum" type="number" maxlength="10" placeholder="请填写"></el-input>
			</el-form-item>
			<el-form-item label="施工内容" style="width:97% !important;margin-top:6px;margin-bottom:16px;">
				<el-input v-model="buildInfo.buildContent" type="textarea" maxlength="200" show-word-limit
					placeholder="请填写"></el-input>
			</el-form-item>
			<div class="a-flex-rfsc a-flex-wrap">
				<le-upload-file-img-ly ref="pic" label="站点全景" labelwidth="112px" v-model="buildInfo.stationImage"
					:limit="4">
				</le-upload-file-img-ly>
				<le-upload-file-img-ly ref="pic" label="空开照片" labelwidth="112px" v-model="buildInfo.switchImage"
					:limit="4">
				</le-upload-file-img-ly>
				<le-upload-file-img-ly ref="pic" label="电表照片" labelwidth="112px" v-model="buildInfo.electricMeterImage"
					:limit="4">
				</le-upload-file-img-ly>
				<le-upload-file-img-ly ref="pic" label="走线照片" labelwidth="112px" v-model="buildInfo.lineImage"
					:limit="4">
				</le-upload-file-img-ly>
				<le-upload-file-img-ly ref="pic" label="地线照片" labelwidth="112px" v-model="buildInfo.earthLineImage"
					:limit="4">
				</le-upload-file-img-ly>
				<le-upload-file-img-ly ref="pic" label="宣传物料照片" labelwidth="112px" v-model="buildInfo.leafletImage"
					:limit="4">
				</le-upload-file-img-ly>
			</div>
			<div class="a-line-t-e0" style="margin: 0 -20px; margin-bottom: -15px;">
				<el-button type="primary" class="a-ml-24 a-mt-15" @click="onSubmit">立即提交</el-button>
				<el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancle">
					&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
				</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
export default {
	props: {
		stationId: {
			type: Number,
		}
	},
	data() {
		return {
			station: {},
			buildInfo: {
				electricNumber: '',
				electricInitNum: 0,
				buildContent: '',
				stationImage: [],
				switchImage: [],
				electricMeterImage: [],
				deviceImage: [],
				earthLineImage: [],
				lineImage: [],
				leafletImage: [],
			}

		}
	},
	watch: {
		stationId: {
			handler(val) {
				if (val) {
					this.getStationBuildInfo();
					this.getStationDetail();
				}
			},
			immediate: true
		}
	},
	methods: {
		/**
		 * 获取站点详细信息
		 */
		getStationDetail() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getStationDetail,
				params: {
					stationId: this.stationId
				}
			}).then(({
				data
			}) => {
				this.station = data.station
			})
		},
		/**
		 * 获取站点施工信息
		 */
		getStationBuildInfo() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getStationBuildInfo,
				params: {
					stationId: this.stationId
				}
			}).then(({ data }) => {
				if (data) {
					Object.assign(this.buildInfo, {
						...data,
						stationImage: data.stationImage ? JSON.parse(data.stationImage) : [],
						switchImage: data.switchImage ? JSON.parse(data.switchImage) : [],
						electricMeterImage: data.electricMeterImage ? JSON.parse(data.electricMeterImage) : [],
						deviceImage: data.deviceImage ? JSON.parse(data.deviceImage) : [],
						earthLineImage: data.earthLineImage ? JSON.parse(data.earthLineImage) : [],
						lineImage: data.lineImage ? JSON.parse(data.lineImage) : [],
						leafletImage: data.leafletImage ? JSON.parse(data.leafletImage) : [],
					})
				}
			})
		},
		submitBuildInfo() {
			return new Promise((resolve, reject) => {
				this.$Axios._post({
					url: this.$Config.apiUrl.submitBuildInfo,
					params: {
						stationId: this.stationId,
						...this.buildInfo,
						stationImage: JSON.stringify(this.buildInfo.stationImage),
						switchImage: JSON.stringify(this.buildInfo.switchImage),
						electricMeterImage: JSON.stringify(this.buildInfo.electricMeterImage),
						deviceImage: JSON.stringify(this.buildInfo.deviceImage),
						earthLineImage: JSON.stringify(this.buildInfo.earthLineImage),
						lineImage: JSON.stringify(this.buildInfo.lineImage),
						leafletImage: JSON.stringify(this.buildInfo.leafletImage),
					}
				}).then(({
					data
				}) => {
					resolve();

				})
			})

		},
		onSubmit() {
			this.submitBuildInfo().then(() => {
				/**
				 * 发送刷新
				 */
				this.$eventBus.$emit('station_fresh_buildInfo')
				this.$message.success("提交施工信息成功")
				this.$emit('success')
			})
		},
		handlerCancle() {
			let that = this
			this.$confirm('是否需要保存施工信息?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.submitBuildInfo().then(() => {
					/**
					 * 发送刷新
					 */
					that.$eventBus.$emit('station_fresh_buildInfo')
					that.$message.success("保存施工信息成功")
					that.$emit('cancel')
				})
			}).catch(() => {
				this.$emit('cancel')
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.construction {

	/deep/ .el-form-item {
		width: 50%;
		display: inline-block;

		.el-input__inner {
			width: 90%;
		}


	}

	.buildInfo {
		height: 100%;
		position: relative;

		.footerBox {
			left: -24px;
			right: 24px;
			position: absolute;
			bottom: -20px;
			background: #fff;
			padding-bottom: 24px;
			margin-bottom: 0;
		}
	}

}

.el-main {
	height: calc(100vh - 195px);
}

/deep/ .lyimgbox {
	margin-top: 8px;
}

/deep/ .el-dialog__body {
	padding-top: 10px;
}
</style>
